import { createTemporaryId } from "../utils/model";

export class PredefinedValue {
  readonly id: string = createTemporaryId();
  readonly value: string;

  constructor(deriveFrom?: Partial<PredefinedValue>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}