import SalaryModel from "./SalaryModel";
import UserPermissions, { UserPermissionsSubject, UserPermission, UserCalendarAccess } from "./UserPermissions";
import { globalUserStore } from "../components/user/UserDispatcher";
import { consequtiveCharCount } from "../utils";
import UserPreferences from "./UserPreferences";
import { UserRight, getImpliedRights } from "s2-rights";
import Team from "./Team";
import Settings from "./Settings";
import { isCreated } from "../utils/model";
import { UserRightsProducts } from "s2-rights";
import { Organization } from "./Organization";
import { getPossessive } from "../utils/semantic";

/**
 * ID of a null user.
 */
export const privateUserId = "5f4d72e00000000000000000";

export enum UsernameStyle {
  Dot = 'dot',
  Dash = 'dash'
}

class PermissionsHolder {
  permissions?: UserPermissions = {};

  /**
   * Looks the permission flag of a given `entityId`.
   * @returns A permission flag, or null if no associated permission flag was found.
   */
  getPermission(subject: UserPermissionsSubject, entityId: string): UserPermission {
    return this.permissions?.[subject]?.[entityId] ?? null;
  }

  setPermission(subject: UserPermissionsSubject, entityId: string, flag: UserPermission) {
    this.permissions = {
      ...this.permissions,
      [subject]: {
        ...(this.permissions?.[subject] || {}),
        [entityId]: flag as any
      }
    };
  }

  /**
   * Resets a permission flag such that it will fallback to the group's.
   */
  resetPermission(subject: UserPermissionsSubject, entityId: string) {
    if (typeof this.permissions?.[subject]?.[entityId] == 'boolean') {
      delete this.permissions[subject][entityId];
    }
  }

  /**
   * @returns A granted permission.
   */
  granted(subject: UserPermissionsSubject) {
    switch (subject) {
      case 'calendar':
        return UserCalendarAccess.FullEdit;
    }

    return true;
  }
}

/**
 * Represents a single user.
 * @author Johan Svensson
 */
export default class User extends PermissionsHolder {
  readonly id: string;
  readonly createdAt: Date = new Date();
  readonly fullName: string;
  readonly username: string;

  /**
   * Username prior to change.
   */
  readonly previousUsername?: string;
  readonly email?: string;
  readonly phone?: string = null;
  readonly type: UserType = UserType.Standard;
  readonly salary: SalaryModel = new SalaryModel();
  readonly rights?: UserRight[] = [];
  readonly superRights?: string[] = [];
  readonly teamId?: string = null;
  readonly roleIds: string[] = [];
  readonly active: boolean = false;
  readonly supervisedTeamIds: string[] = [];
  readonly mfa?: {
    enabled?: boolean;
    smsRecipient?: string;
  } = {
      enabled: false,
      smsRecipient: null
    };
  readonly timezone = "Europe/Stockholm";
  readonly suspension?: UserSuspension = null;
  readonly language?: string;
  private readonly cachedPreferences: { [organizationKey: string]: UserPreferences };

  /**
   * NOTE: use `getPreferences` to get the user's final/effective preferences,
   * which includes default values.
   * 
   * This object contains specified preferences.
   */
  readonly preferences = new UserPreferences();

  constructor(deriveFrom?: Partial<User>) {
    super();

    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    this.cachedPreferences = {}; // Ignore from deriveFrom.

    if (this.preferences) {
      this.preferences = new UserPreferences(this.preferences);
    }

    if (this.salary) {
      this.salary = new SalaryModel(deriveFrom.salary);
    }

    if (this.suspension) {
      this.suspension = new UserSuspension(this.suspension);
    }

    if (this.teamId) {
      //  teamId has implied presence in supervisedTeamIds. Remove it to improve some stuff visually. 
      this.supervisedTeamIds = this.supervisedTeamIds.filter(supervisedTeamId => supervisedTeamId !== this.teamId);
    }

    if (this.preferences && !this.hasRight(UserRightsProducts.ProductsGetPrice)) {
      /**  Salary basis should never exclude VAT (apparently). Excerpt from s2-api-main:
       * if (opts.user?.hasRight(UserRightsProducts.ProductsGetPrice) === false) {
       *    delete config.includeVatInSum;
       * }
       */
      this.preferences = new UserPreferences({
        ...this.preferences,
        includeVatInSum: undefined
      });
    }
  }

  /**
   * Returns this user's preferences.
   * If `organization` is set, then falls back to the organization's default preferences.
   * Otherwise, the `preferences` object is returned as-is.
   * 
   * `organization` should be set first handedly to get more accurate preferences.
   */
  getPreferences(organization: Organization): UserPreferences {
    const cacheKey = organization.defaultUserPreferences ? organization.key : 'none';

    //  Minimize the number of instantiated objects, with cache.
    if (this.cachedPreferences[cacheKey]) {
      return this.cachedPreferences[cacheKey];
    }

    if (!organization.defaultUserPreferences) {
      return this.cachedPreferences[cacheKey] = UserPreferences.withDefaults(this.preferences);
    }

    //  Only include specific properties from default preferences.
    //  For example, if `callConnectNotificationEnabled` was accidentally set to false,
    //  in the default preferences then that feature would not be disabled for everyone.
    return this.cachedPreferences[cacheKey] =
      UserPreferences.withDefaults(
        this.preferences.merge(organization.defaultUserPreferences,
          'enabledCallListHeadingIds', 'enabledContactListHeadingIds',
          'enabledOfferListHeadingIds', 'enabledOrderListHeadingIds',
          'enabledDialGroupListHeadingIds', 'includeVatInSum',
          'enabledCallUtilities', 'calendarCallbackEnabled'
        )
      );
  }

  getPermission(subject: UserPermissionsSubject, entityId: string): UserPermission {
    if (this.isAdmin || entityId == this.id) {
      return this.granted(subject);
    }
    return super.getPermission(subject, entityId);
  }

  get isAdmin() {
    return this.type === UserType.Admin;
  }

  /**
   * Whether this is a system user.
   */
  get isSystemUser() {
    return this.type === UserType.SystemUser;
  }

  /**
   * Returns teams whose resources may be accessed by this user.
   * The user's supervised teams are included in this list.
   * If the user is a member of a team, that team is included as well.
   */
  get accessibleTeamIds() {
    let teamIds: string[] = [...this.supervisedTeamIds];

    if (this.teamId) {
      teamIds.push(this.teamId);
    }

    return teamIds;
  }

  get status(): UserStatus {
    if (this.suspension) {
      return UserStatus.Suspended;
    }

    if (!this.active) {
      return UserStatus.Inactive;
    }

    return UserStatus.Active;
  }

  /**
   * Yhe position offset in the user list.
   */
  getListPositionOffset(): number {
    switch (this.status) {
      case UserStatus.Inactive:
        return 1;

      case UserStatus.Suspended:
        return 2;
    }

    return 0;
  }

  hasAnyAdminSuperRight() {
    return this.superRights?.find(right => right.startsWith('admin.'));
  }

  hasAnySupportSuperRight() {
    return this.superRights?.find(right => right.startsWith('support.'));
  }

  /**
   * @param teamId ID of the team.
   * @returns Whether this user is a member of a team with a given ID.
   */
  isMemberOfTeam(teamId: string) {
    return this.teamId && this.teamId === teamId;
  }

  /**
   * @param teamIds ID of a set of teams.
   * @returns Whether this user is a member of any team included in the list.
   */
  isMemberOfAnyTeam(teamIds: string[]) {
    return this.teamId && teamIds.includes(this.teamId);
  }

  /**
   * @param user Another user
   * @returns Whether this user is a member of any team which `user` has right to access. 
   */
  isMemberOfAnyUsersTeam(user: User) {
    const teamIds: string[] = [user.teamId, ...(user.supervisedTeamIds || [])].filter(Boolean);
    return this.isMemberOfAnyTeam(teamIds);
  }

  get firstName() {
    const { fullName } = this;
    if (!fullName) {
      return '';
    }

    return fullName.split(' ')[0];
  }

  get firstNamePossessive() {
    return getPossessive(this.firstName);
  }

  get usernamePossessive() {
    return this.username.replace(/s$/i, '') + 's';
  }

  get isCreated() {
    return isCreated(this.id);
  }

  get isSuspended() {
    return this.status === UserStatus.Suspended;
  }

  /**
   * @returns The full name with the preferred format.
   */
  getPreferredName(others: User[] = globalUserStore.state.users.all) {
    if (this.isSystemUser) {
      return this.fullName;
    }

    return this.fullNameAbbreviated(others);
  }

  /**
   * @example "Alexa Alexis" -> "Alexa" or "Alexis A"
   */
  getCompactName(others: User[] = globalUserStore.state.users.all || []) {
    const { firstName, id } = this;

    if (others.find(other => other.id !== id && other.firstName == firstName)) {
      return this.fullNameAbbreviated(others);
    }

    return firstName;
  }

  /**
   * @example Alexa Alexis -> Alexis A
   */
  fullNameAbbreviated(others: User[] = globalUserStore.state.users.all || []) {
    const components = this.fullName.split(' ', 2);
    if (components.length == 1) {
      return components[0];
    }

    if (!others?.length) {
      return `${components[0]} ${components[1].substr(0, 1)}`;
    }

    let abbreviationLength = 1;

    for (let other of others) {
      if (other == this) {
        continue;
      }

      const otherComponents = other.fullName.split(' ', 2);
      if (otherComponents.length < 2 || otherComponents[0] != components[0]
        || otherComponents[1] == components[1]) {
        continue;
      }

      abbreviationLength = Math.max(abbreviationLength, consequtiveCharCount(components[1], otherComponents[1]) + 1);
    }

    return `${components[0]} ${components[1].substr(0, abbreviationLength)}`;
  }

  fullNameAbbreviatedPossessive(others: User[] = globalUserStore.state.users.all || []) {
    const abbreviated = this.fullNameAbbreviated(others);
    const components = abbreviated.split(' ', 2);
    if (components.length == 1) {
      return getPossessive(components[0]);
    }

    return getPossessive(abbreviated);
  }

  /**
   * @param right The user right to check.
   * @returns Whether this user has a given right. For administrators, `true` is returned. If rights are hidden, `null` is hidden.
   */
  hasRight(right: UserRight) {
    if (this.isAdmin) {
      return true;
    }

    if (!this.rights) {
      return null;
    }

    return this.rights.find(otherRight => {
      if (getImpliedRights(otherRight).indexOf(right) !== -1) {
        //  implicitly enabled
        return true;
      }

      return this.rights.indexOf(right) !== -1;
    }) != null;
  }

  /**
   * @param rights The user rights to check.
   * @returns Whether this user has any one of the given rights. For administrators, `true` is returned. 
   */
  hasAnyRight(rights: UserRight[]) {
    if (this.isAdmin) {
      return true;
    }

    return rights.find(right => this.hasRight(right)) != null;
  }

  /**
   * @param rights The user rights to check.
   * @returns Whether this user has all of the given rights. For administrators, `true` is returned. 
   */
  hasAllRights(rights: UserRight[]) {
    if (this.isAdmin) {
      return true;
    }

    return rights.filter(right => this.hasRight(right)).length === rights.length;
  }

  /**
   * Returns whether the user has a given `superRight`, which are beyond regular rights.
   * @param superRight Super right of which to check.
   * @returns Whether the user has a given `superRight`.
   */
  hasSuperRight(superRight: string) {
    return this.superRights?.includes(superRight);
  }

  /**
   * Returns a flat list of team IDs.
   */
  listTeamIds(): string[] {
    const teamIds: string[] = [];

    if (this.teamId) {
      teamIds.push(this.teamId);
    }

    this.supervisedTeamIds?.forEach(teamId => teamIds.push(teamId));
    return teamIds;
  }
}

class UserSuspension {
  userId?: string;
  suspendedAt?: Date;

  constructor(deriveFrom?: Partial<UserSuspension>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.suspendedAt) {
      this.suspendedAt = new Date(this.suspendedAt);
    }
  }
}

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended'
}

export enum UserType {
  Admin = 'admin',
  SystemUser = 'systemUser',
  Standard = 'standard'
}

export type SystemUserCredentials = {
  username: string;
  password: string;
}