import { pad } from "../utils";
import moment, { Moment } from "moment";
import "moment-timezone";
import { text } from "./i18n";

let timezone = moment.tz.guess();


/**
 * Sets the timezone used for date utilities.
 * @example Europe/Stockholm
 */
export function setTimezone(tz: string) {
  timezone = tz;
}

/**
 * @returns the timezone used for date utilities.
 */
export function getTimezone() {
  return timezone;
}

export type DateFormatOptions = {
  time?: 'auto' | boolean;
  year?: 'auto' | 'include' | 'exclude';
  hideTodaysDate?: boolean;
  compact?: boolean;
  weekday?: boolean;
};

export function formatDate(date = new Date(), opts?: DateFormatOptions) {
  const mDate = date ? moment(date) : null;
  const compact = opts?.compact ?? true;

  if (!mDate?.isValid()) {
    return text`Okänt datum`;
  }

  if (mDate.unix() < 10) {
    return text`Sen början`;
  }

  let formatStringParts: string[] = [];

  if (!opts?.hideTodaysDate || moment().format('YYYY-MM-DD') !== mDate.format('YYYY-MM-DD')) {
    formatStringParts.push(`D ${compact ? "MMM" : "MMMM"}`);
  }

  if (opts?.weekday) {
    formatStringParts = [
      'ddd',
      ...formatStringParts
    ];
  }

  if (opts?.year !== "exclude" && (opts?.year === "include" || mDate.year() !== moment().year())) {
    formatStringParts.push('YYYY');
  }

  if (opts?.time === true || (opts?.time == 'auto' && (mDate.hours() || mDate.minutes()))) {
    formatStringParts.push('HH:mm');
  }

  return mDate.format(formatStringParts.join(' '));
}


/**
 * @param to Target time. 
 * @param from Source time. Defaults to current.
 * @returns Time difference from source time to target time.
 */
export function timeDiff(to: Date | number, from: Date | number = Date.now(), roundFn = Math.floor) {
  if (to instanceof Date) {
    to = to.getTime();
  }
  if (from instanceof Date) {
    from = from.getTime()
  }

  let delta = to - from;

  let { units } = Time;
  let unit = 0;

  //  delta to mins
  delta = roundFn(delta / (60 * 1000));

  const isPast = delta <= 0;

  if (isPast) {
    delta = Math.abs(delta);
  }

  delta = Math.max(delta, 1);

  unit = delta == 1 ? units.minute : units.minutes;
  if (delta >= 60) {
    //  To hours
    delta = roundFn(delta / 60);
    unit = delta == 1 ? units.hour : units.hours;

    if (delta >= 24) {
      //  To days
      delta = roundFn(delta / 24);
      unit = delta == 1 ? units.day : units.days;

      if (delta >= 7) {
        //  To weeks
        delta = roundFn(delta / 7);
        unit = delta == 1 ? units.week : units.weeks;

        if (delta >= 4) {
          //  To months
          delta = roundFn(delta / 4);
          unit = delta == 1 ? units.month : units.months;
        }
      }
    }
  }

  return {
    unit: unit,
    delta: delta,
    isPast: isPast,
    stringVal: `${delta} ${text(Time.unitStrings[unit])}`
  }
}

export class Time {
  static unitStrings = [
    "min",
    "min",
    "timme",
    "timmar",
    "dag",
    "dagar",
    "vecka",
    "veckor",
    "månad",
    "månader"
  ];
  static unitStringsAbbr = [
    "m",
    "m",
    "h",
    "h",
    "d",
    "d",
    "w",
    "w",
    "mo",
    "mo"
  ];
  static months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Maj",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec"
  ]
  static weekDaysAbbr = [
    "Sön",
    "Mån",
    "Tis",
    "Ons",
    "Tor",
    "Fre",
    "Lör"
  ]
  static weekDays = [
    "Söndag",
    "Månadg",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag"
  ]
  static units = {
    minute: 0,
    minutes: 1,
    hour: 2,
    hours: 3,
    day: 4,
    days: 5,
    week: 6,
    weeks: 7,
    month: 8,
    months: 9,
  }
  delta = 0;
  unit = 0;
  isPast = false;
}

/**
 * @returns A UTC `moment` instance with the same year, month and day as `date`, also at start of day.
 */
export function startOfDay(date: Date | Moment) {
  if (!date) {
    return null;
  }
  return moment(date).startOf('day').toDate();
}

/**
 * @returns A UTC `moment` instance with the same year, month and day as `date`, also at start of day.
 */
export function endOfDay(date: Date | Moment) {
  if (!date) {
    return null;
  }
  return moment(date).endOf('day').toDate();
}

Date.prototype.toString = function () {
  if (isNaN(this.getTime())) {
    return null;
  }

  return moment(this).toISOString(true);
}

/**
 * @returns Whether two dates are equal.
 */
export function datesEqual(d1: Date, d2: Date) {
  if (!d1 && !d2) {
    return true;
  }

  if (!!d1 != !!d2) {
    return false;
  }

  return d1.getTime() == d2.getTime();
}

/**
 * Formats a duration to more specific string than `moment.format`.
 * @param duration Duration in milliseconds.
 */
export function formatDuration(duration?: number): { shortest: string; short: string; humanReadable: string; humanReadableShort: string; } {
  if (typeof duration !== 'number' || isNaN(duration)) {
    return null;
  }

  if (duration < 1) {
    duration = 1;
  }

  const humanReadableComponents: string[] = [];
  const humanReadableShortComponents: string[] = [];
  const shortestComponents: string[] = [];
  const shortComponents: string[] = [];

  const momentDuration = moment.duration(duration, 'milliseconds');

  const hours = Math.max(0, Math.floor(momentDuration.asHours()));
  if (hours) {
    shortComponents.push(pad(hours));

    const humanReadable = hours === 1 ? 'en timme' : `${hours.toLocaleString('sv')} timmar`;
    humanReadableComponents.push(humanReadable);
    humanReadableShortComponents.push(humanReadable);
    shortestComponents.push(hours + 'h');
  }

  const minutes = Math.max(0, Math.floor(momentDuration.asMinutes()) % 60);
  shortComponents.push(pad(minutes));

  if (minutes) {
    const humanReadable = minutes === 1 ? 'en minut' : `${minutes.toLocaleString('sv')} minuter`;
    humanReadableComponents.push(humanReadable);
    humanReadableShortComponents.push(humanReadable);
    shortestComponents.push(minutes + 'm');
  }

  const seconds = Math.max(0, Math.floor(momentDuration.asSeconds()) % 60);
  shortComponents.push(pad(seconds));
  if (seconds) {
    humanReadableComponents.push(seconds === 1 ? 'en sekund' : `${seconds.toLocaleString('sv')} sekunder`);
  }

  if (seconds && !hours) {
    shortestComponents.push(seconds + 's');
  }

  return {
    humanReadable: humanReadableComponents.join(' '),
    humanReadableShort: humanReadableShortComponents.join(' och ') || 'några sekunder',
    short: shortComponents.join(':'),
    shortest: shortestComponents.join(' ')
  }
}

/**
 * @returns `millis` formatted as <HH:>MM:SS
 */
export function formatTime(millis: number) {
  const duration = moment.duration(millis, 'milliseconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let components: number[] = [];
  if (hours) {
    components.push(hours);
  }

  components.push(Math.max(0, minutes % 60));
  components.push(Math.max(0, seconds % 60));

  return components.map(component => pad(component, 2, '0')).join(':');
}

export function formatTimeHumanized(millis: number) {
  const duration = moment.duration(millis, 'milliseconds');
  const hours = duration.hours() + 24 * duration.days();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours && minutes) {
    return `${hours}h ${minutes}m`;
  }

  if (hours) {
    return `${hours}h`;
  }

  if (minutes && seconds) {
    return `${minutes}m ${seconds}s`;
  }

  if (minutes) {
    return `${minutes}m`;
  }

  if (seconds) {
    return `${seconds}s`;
  }

  return "";
}

export function isWeekend(date: Date) {
  return [0, 6].includes(date.getUTCDay());
}

/**
 * @param workdaysToAdvance Number of work days to add to `from`
 * @param from Base date
 * @returns Next date, at minimum `workdaysToAdvance` days from `from`, and which is a workday.
 */
export function getAvailableWorkday(workdaysToAdvance: number, from = new Date()): Date {
  let date = new Date(from);

  function skipWeekend() {
    while (isWeekend(date)) {
      date.setDate(date.getDate() + 1);
    }
  }

  for (let i = 0; i < workdaysToAdvance; i++) {
    skipWeekend();
    date.setDate(date.getDate() + 1);
  }

  skipWeekend();

  return date;
}

export function nextWorkday(date: Date): Date {
  while (isWeekend(date)) {
    date.setDate(date.getDate() + 1);
  }

  return date;
}

/**
 * 
 * @example setRelativeDateExpression("2 hours", 5) // "5 hours"
 * @example setRelativeDateExpression("2 hours", undefined, "days") // "2 days"
 */
export function setRelativeDateExpression(expression: string | null | undefined, value?: number, unit?: string): string {
  const components = expression?.split(' ', 2) ?? [];

  if (components.length !== 2) {
    components[0] = "0";
    components[1] = "hour";
  }
  
  if (value || value === 0) {
    components[0] = String(value);
  }

  if (unit) {
    components[1] = unit;
  }

  return components.join(' ');
}