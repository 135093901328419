import { Language, getLanguage } from "../utils/i18n";
import { getUserId } from "../utils/user";
import User, { UserStatus } from "./User";

/**
 * Optimized store of users.
 * Caches common, calculated user properties such as preferred name.
 */
export class Users {

  private userLookup: { [userId: string]: User; } = {};
  private userLookupByUsername: { [username: string]: User; } = {};
  private userLookupByTeamId: { [teamId: string]: User[]; } = {};
  private preferredNameLookup: { [userId: string]: string; } = {};
  private abbreviatedFullNameNameLookup: { [userId: string]: string; } = {};
  readonly current: User;
  readonly activeUsers: User[];

  constructor(public readonly all: User[]) {
    all.forEach(user => {
      this.userLookup[user.id] = user;
      this.userLookupByUsername[user.username] = user;
      this.preferredNameLookup[user.id] = user.getPreferredName(all);
      this.abbreviatedFullNameNameLookup[user.id] = user.fullNameAbbreviated(all);

      for (const teamId of user.listTeamIds()) {
        if (!this.userLookupByTeamId[teamId]) {
          this.userLookupByTeamId[teamId] = [];
        }

        this.userLookupByTeamId[teamId].push(user);
      }
    });
    
    //  Include admin even though the user has not been activated yet.
    //  This fixes potential problems when logging into admin via support login
    //  before the admin user has been activated.
    this.activeUsers = all.filter(user => user.status === UserStatus.Active || user.isAdmin);

    all.push = all.splice = this.activeUsers.push = this.activeUsers.splice = () => { throw new Error('Array is immutable'); }

    this.current = this.getUser(getUserId());
  }

  /**
   * @returns A user with given `userId`.
   */
  getUser(userId: string) {
    if (!userId) {
      return null;
    }
    
    return this.userLookup[userId];
  }

  /**
   * @returns A user with given `userId`.
   */
  getUserByUsername(username: string) {
    return this.userLookupByUsername[username];
  }

  /**
   * @returns A user with given `teamId`.
   */
  listUsersByTeamId(teamId: string) {
    return this.userLookupByTeamId[teamId] || [];
  }

  /**
   * @returns Preferred name of user with given `userId`.
   */
  getPreferredName(userId: string) {
    if (this.isPrivateUser(userId)) {
      return 'SaleSys';
    }

    return this.preferredNameLookup[userId];
  }

  /**
   * @returns Abbreviated full name of user with given `userId`.
   */
  getFullNameAbbreviated(userId: string) {
    return this.abbreviatedFullNameNameLookup[userId];
  }

  get length() {
    return this.all.length;
  }

  /**
   * @returns Whether this is a private user which represents an internal system service.
   */
  isPrivateUser(userId: string) {
    return userId?.includes('000000000000');
  }
}